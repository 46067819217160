import "@/styles/globals.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { ReactElement, ReactNode } from "react";
import { NextIntlClientProvider } from "next-intl";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Toaster } from "@/components/atoms/shadcn/ui/toaster";
import { SessionProvider } from "next-auth/react";
const queryClient = new QueryClient();
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <NextIntlClientProvider
          locale={router.locale}
          timeZone="America/Mexico_City"
          messages={pageProps.messages}
        >
          {getLayout(<Component {...pageProps} />)}
        </NextIntlClientProvider>
      </QueryClientProvider>
      <Toaster />
    </SessionProvider>
  );
}
export default App;
